import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ConexaoService } from 'src/services/conexao.service';

@Component({
  selector: 'app-inc-usuario',
  templateUrl: './inc-usuario.page.html',
  styleUrls: ['./inc-usuario.page.scss'],
})
export class IncUsuarioPage implements OnInit {

  public cpf: number;
  public nome: string;
  public senha: string;
  public nivel: number;
  public loading: any;
  public total: number = 0;
  public operadoras: any;

  constructor(
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public navParams: NavParams,
    public conexaoServico: ConexaoService
  ) { }

  ngOnInit() {
  }

  criarUsuario() {
    var inclui = true;

    if (this.cpf == 0) {
      alert('Digite um CPF válido!');
      inclui = false;
    }
   
    if (this.nome == undefined) {
      alert('Digite um Nome válido!');
      inclui = false;
    }

    if (this.senha == undefined || this.senha.length <= 5) {
      alert('Digite uma Semha válida, com pelo menos 6 caracteres!');
      inclui = false;
    }

    if (this.nivel <= 0 || this.nivel == undefined) {
      alert('Selecione um niível válido!');
      inclui = false;
    }

    if (inclui) {
      console.log('inclui');
      this.incUsuario();
    }
  }

  async incUsuario() {
    this.loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Enviando ...'
    });
    this.loading.present().then(() => {
      this.conexaoServico.putUsuario('add', 0, this.cpf, this.nome, this.senha, this.nivel).subscribe((data) => {
        console.log(data);
        this.modalCtrl.dismiss();
      });
      this.loading.dismiss();
    });
  }

  fechar() {
    this.modalCtrl.dismiss();
  }

  
}
