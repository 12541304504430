import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ConexaoService } from 'src/services/conexao.service';

@Component({
  selector: 'app-edita-tarifa',
  templateUrl: './edita-tarifa.page.html',
  styleUrls: ['./edita-tarifa.page.scss'],
})
export class EditaTarifaPage implements OnInit {

  public taxa: any;
  public operadora: any;
  public idoperadora: any;
  public cartao: any;
  public idcartao: any;
  public loading: any;


  constructor(
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    private navParams: NavParams,
    public conexaoServico: ConexaoService
  ) { }

  ngOnInit() {
    this.taxa = this.navParams.data.taxa;
    this.operadora = this.navParams.data.operadora;
    this.idoperadora = this.navParams.data.idoperadora;
    this.cartao = this.navParams.data.cartao;
    this.idcartao = this.navParams.data.idcartao;

    // console.log(this.taxa);
    // console.log(this.operadora);
    // console.log(this.idoperadora);
    // console.log(this.cartao);
    // console.log(this.idcartao);
  }

  fechar() {
    this.modalCtrl.dismiss();
  }

  salvaTarifa() {
    var edita = true;

    if (this.taxa.TipoVenda == 'D') {
      this.taxa.Parcela_Ini = 1;
      this.taxa.Parcela_Fin = 1;
      this.taxa.Prazo = 1;
    }

    if (this.taxa.Parcela_Ini == 0) {
      alert('Selecione o número da parcela inicial correto!');
      edita = false;
    }

    if (this.taxa.Parcela_Fin == 0) {
      alert('Selecione o número da parcela final correto!');
      edita = false;
    }

    if (this.taxa.Prazo <= 0 || this.taxa.Prazo == undefined) {
      alert('Digite um prazo Válido!');
      edita = false;
    }

    if (this.taxa.TaxaAdm == 0 || this.taxa.TaxaAdm == undefined) {
      alert('Digite uma Taxa Válida!');
      edita = false;
    }

    if (edita) {
      // console.log(this.taxa.idTarifa);
      // console.log(this.idoperadora);
      // console.log(this.idcartao);
      // console.log(this.taxa.TipoVenda);
      // console.log(this.taxa.Parcela_Ini);
      // console.log(this.taxa.Parcela_Fin);
      // console.log(this.taxa.Prazo);
      // console.log(this.taxa.TaxaAdm);
      this.editaTarifa();
    }
  }

  async editaTarifa() {
      this.loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: 'Enviando ...',
      });
      this.loading.present().then(() => {
        this.conexaoServico.putTarifa('upd', this.taxa.idTarifa , this.idoperadora, this.idcartao, this.taxa.TipoVenda, this.taxa.Parcela_Ini, this.taxa.Parcela_Fin, this.taxa.Prazo, this.taxa.TaxaAdm).subscribe((data) => {
          // console.log(data);
          this.modalCtrl.dismiss();
      });
      this.loading.dismiss();
    });
  }
}