import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-lancamento-detalhe',
  templateUrl: './lancamento-detalhe.page.html',
  styleUrls: ['./lancamento-detalhe.page.scss'],
})
export class LancamentoDetalhePage implements OnInit {

  public lancamento: any;
  public valorTX: number;

  constructor(
    public modalCtrl: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.lancamento = this.navParams.data.lancamento;
  }

  fechar() {
    // const data = { cadastro: false };
    this.modalCtrl.dismiss();
  }
}
