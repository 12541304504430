import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.page.html',
  styleUrls: ['./periodo.page.scss'],
})
export class PeriodoPage implements OnInit {

  constructor(
    public modalCtrl: ModalController,
    private navParams: NavParams) {
    
  }

  public dataInicial: any;
  public dataFinal: any;

  ngOnInit() {
    // this.dataInicial = formatDate(this.navParams.data.dataInicial,'dd/MM/yyyy','en-GB');
    // this.dataFinal = formatDate(this.navParams.data.dataFinal,'dd/MM/yyyy','en-GB');

    this.dataInicial = this.navParams.data.dataInicial;
    this.dataFinal = this.navParams.data.dataFinal;
    
    this.dataInicial = this.converteData(this.dataInicial);
    this.dataFinal = this.converteData(this.dataFinal);
    console.log(this.dataInicial);
    console.log(this.dataFinal);
  }

  converteData(datas: string) {
    let padrao = /(\d{2})\/(\d{2})\/(\d{4})/;
    datas = datas + ' 03:00';
    let dataf = new Date(datas.replace(padrao, '$3-$2-$1')); 
    return formatDate(dataf, 'yyyy/MM/dd', 'en-US')
  }

  fechar() {
    
    this.dataInicial = formatDate(this.dataInicial,'dd/MM/yyyy','en-GB');
    this.dataFinal = formatDate(this.dataFinal,'dd/MM/yyyy','en-GB');
    if (this.dataFinal > this.dataInicial) {
      console.log('erro');
    }
    const data = { 
      'dataInicial': this.dataInicial,
      'dataFinal': this.dataFinal
    };
    this.modalCtrl.dismiss(data);
  }
}
